// Importing custom CSS with Bootstrap 4, React Bootstrap JS and SplideJS carousel styles
import "./src/scss/msf.scss"
import "@splidejs/splide/dist/css/splide.min.css"
import "./node_modules/react-bootstrap/dist/react-bootstrap"

// https://stackoverflow.com/questions/55336831/how-to-fix-gatsby-js-link-component-retaining-scroll-position-and-not-resetting
// Reset scroll position on every page to be on top
export function shouldUpdateScroll(prevRouterProps, { location }) {
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName("body")[0]
  body.scrollTop = 0
  return false
}
